export const TRANSLATIONS_RU = {
    "home_link": "Главная",
    "about_link": "О нас",
    "approach_link": "Наш подход",
    "clients_link": "Наши клиенты",
    "services_link": "Сервисы",
    "contact_link": "Контакты",
    "company_name": "VetMed Pharma",
    "get_started": "Начать",
    "address": "Адресс",
    "email": "Эл.почта",
    "phone": "Телефон",
    "name": "Имя",
    "message": "Сообщение",
    "required": "Обязательно",
    "invalid_email": "Неправильная эл.почта",
    "min_char": "Минимум 2 символа.",
    "send_message": "Отправить сообщение",
    "copyrights": "© VETMED PHARMA | ВСЕ ПРАВА ЗАЩИЩЕНЫ.",
    "home_title": "Мы",
    "home_title-bold": "VetMed Pharma",
    "home_subtitle": "Наш опыт состоит в разработке, производстве, маркетинге и продаже высококачественных продуктов исключительно для ветеринаров на внутреннем рынке и рынках СНГ.",
    "about_title": "О",
    "about_paragraph-first": "Наша цель – постоянное улучшение глобального здоровья и благосостояния животных. VetMed Pharma является специализированной компанией в сфере ветеринарной фармацевтики и сопутствующих товаров. Наш опыт состоит в разработке, производстве, маркетинге и продаже высококачественных продуктов исключительно для ветеринаров на внутреннем рынке и рынках СНГ.",
    "about_paragraph-second": "Все, что мы делаем, основывается на нашей культуре и ценностях. Мы верим, что наши ценности воплощают нашу бизнес-этику и устанавливают стандарты, которые мы стремимся достичь и в конце концов превзойти. Они определяют тип людей, которыми мы являемся, услуги, которые мы предоставляем, и то, как мы стремимся вести бизнес. Мы предоставляем высококачественные продукты и услуги ветеринарам в Молдове и многих других странах СНГ через наших сотрудников и сеть третьих сторон для стабильного улучшения здоровья и благосостояния животных.",
    "about_quality_standarts_title": "Стандарты Качества",
    "about_quality_standarts_subtitle": "В основе нашего подхода разработаны ",
    "about_quality_standarts_link": "строгие стандарты качества.",
    "about_relationships_title": "Долгосрочные отношения",
    "about_relationships_subtitle": "Мы разработали долгосрочные отношения с поставщиками и клиентами для развития чрезвычайно успешного бизнеса.",
    "about_clients_needs_title": "Потребности клиента",
    "about_clients_needs_subtitle": "Знание ожиданий и потребностей наших клиентов является ключом к эффективности нашего бизнеса.",
    "approach": "Наш подход",
    "approach_subtitle": "Мы — команда преданных делу профессионалов, понимающих потребности и желания наших клиентов.",
    "approach-services": "Сервисы",
    "approach-services_title": "Мы являемся креативным агентством.",
    "approach-services_subtitle-bold": "Одним из наших главных приоритетов является предоставление ",
    "approach-services_subtitle": "нашим клиентам пятизвездочного уровня обслуживания.",
    "passionate": "Преданность",
    "professionals": "Профессионалы",
    "trust": "Доверие",
    "clients_title": "Наши",
    "clients_title-bold": "Клиенты",
    "clients_subtitle": "Наша клиентская база состоит из различных фармацевтических компаний",
    "clients_companies": "Академические фармацевтические компании",
    "clients_contract": "Контракты",
    "clients_private-label": "Частная торговая марка",
    "clients_organizations": "Государственные организации",
    "services_title": "Гарантия ",
    "services_title-bold": "Качества",
    "services_subtitle": "Мы в VetMed Pharma отдаем приоритет наивысшему качеству продуктов и услуг, одновременно заботясь об окружающей среде, здоровье животных и людей. Мы осознаем важность науки, технологий и научно-исследовательских разработок и работаем в соответствии с самыми высокими стандартами качества фармацевтической промышленности, как GMP и GLP. Посредством нашей системы управления качеством в сочетании с нашей системой управления цепью поставок мы наблюдаем за нашими поставщиками, чтобы убедиться, что они соответствуют нашим ожиданиям. Кроме того, в наших продуктах мы используем ингредиенты высочайшего качества только из ЕС.",
    "services_specialty": "Специализированные фармацевтические компании",
    "services_formulation": "Компании, занимающиеся исследованиями и разработкой рецептур",
    "services_chemical": "Химические компании",
    "suppliers_title": "Новые поставщики/клиенты",
    "suppliers_subtitle": "Спасибо, что выбрали VetMed Pharma своим брокером! Пожалуйста, заполните форму ниже, и мы свяжемся с вами.",    
    "contacts_title": "Обратная ",
    "contacts_title-bold": "связь",
    "contacts_subtitle": "Спасибо за ваш интерес к VetMed Pharma. Если вы хотите узнать больше о наших сервисах, пожалуйста, свяжитесь с нами онлайн.",
    "contact_us": "Контакты: ",
    "form_title": "Отправка успешная",
    "form_paragraph": "Спасибо за потраченное время, чтобы связаться с нами.",
}
