export const TRANSLATIONS_UA = {
    "home_link": "Головна",
    "about_link": "Про нас",
    "approach_link": "Наш підхід",
    "clients_link": "Наші клієнти",
    "services_link": "Сервіси",
    "contact_link": "Контакти",
    "company_name": "VetMed Pharma",
    "get_started": "Розпочати",
    "address": "Адреса",
    "email": "Е-пошта",
    "phone": "Телефон",
    "name": "Ім'я",
    "message": "Повідомлення",
    "required": "Обов'язково",
    "invalid_email": "Неправильна е-пошта",
    "min_char": "Мінімум 2 символи.",
    "send_message": "Відправити повідомлення",
    "copyrights": "© VETMED PHARMA | УСІ ПРАВА ЗАХИЩЕНО.",
    "home_title": "Ми",
    "home_title-bold": "VetMed Pharma",
    "home_subtitle": "Наш досвід полягає в розробці, виробництві, маркетингу та продажу високоякісних продуктів виключно для ветеринарів на внутрішньому ринку та ринках СНД.",
    "about_title": "Про",
    "about_paragraph-first": "Наша мета — стале покращення глобального здоров’я та добробуту тварин. VetMed Pharma є спеціалізованою компанією у сфері ветеринарної фармацевтики та супутніх товарів. Наш досвід полягає в розробці, виробництві, маркетингу та продажу високоякісних продуктів виключно для ветеринарів на внутрішньому ринку та ринках СНД.",
    "about_paragraph-second": "Усе, що ми робимо, базується на нашій культурі та цінностях. Ми віримо, що наші цінності втілюють нашу бізнес-етику та встановлюють стандарти, які ми прагнемо досягти та зрештою перевершити. Вони окреслюють тип людей, якими ми є, послуги, які ми надаємо, і те, як ми прагнемо вести бізнес. Ми надаємо високоякісні продукти та послуги ветеринарам у Молдові та багатьох інших країнах СНД через наших співробітників і мережу третіх сторін для стабільного покращення здоров’я та добробуту тварин.",
    "about_quality_standarts_title": "Стандарти Якості",
    "about_quality_standarts_subtitle": "В основі нашого підходу розроблені ",
    "about_quality_standarts_link": "суворі стандарти якості.",
    "about_relationships_title": "Довгострокові відносини",
    "about_relationships_subtitle": "Ми розробили довгострокові відносини з постачальниками та клієнтами для розвитку надзвичайно успішного бізнесу.",
    "about_clients_needs_title": "Потреби клієнта",
    "about_clients_needs_subtitle": "Знання очікувань і потреб наших клієнтів є ключем до ефективності нашого бізнесу.",
    "approach": "Наш підхід",
    "approach_subtitle": "Ми — команда відданих справі професіоналів, які розуміють потреби та бажання наших клієнтів.",
    "approach-services": "Сервіси",
    "approach-services_title": "Ми Є Креативною Агенцією.",
    "approach-services_subtitle-bold": "Одним із наших головних пріоритетів є надання ",
    "approach-services_subtitle": "нашим клієнтам п’ятизіркового рівня обслуговування.",
    "passionate": "Відданість",
    "professionals": "Професіонали",
    "trust": "Довіра",
    "clients_title": "Наші",
    "clients_title-bold": "Клієнти",
    "clients_subtitle": "Наша клієнтська база складається з різноманітних фармацевтичних компаній",
    "clients_companies": "Академічні фармацевтичні компанії",
    "clients_contract": "Контракти",
    "clients_private-label": "Приватна торгова марка",
    "clients_organizations": "Державні організації",
    "services_title": "Гарантія ",
    "services_title-bold": "Якості",
    "services_subtitle": "Ми у VetMed Pharma віддаємо пріоритет найвищій якості продуктів і послуг, водночас дбаючи про навколишнє середовище, здоров’я тварин і людей. Ми усвідомлюємо важливість науки, технологій та науково-дослідних розробок і працюємо відповідно до найвищих стандартів якості фармацевтичної промисловості, якими є GMP та GLP. За допомогою нашої системи управління якістю в поєднанні з нашою системою управління ланцюгом поставок ми спостерігаємо за нашими постачальниками, щоб переконатися, що вони відповідають нашим очікуванням. Крім того, у наших продуктах ми використовуємо інгредієнти найвищої якості лише з ЄС. ",
    "services_specialty": "Спеціалізовані фармацевтичні компанії",
    "services_formulation": "Компанії, що займаються дослідженнями та розробкою рецептур",
    "services_chemical": "Хімічні компанії",
    "suppliers_title": "Нові постачальники/клієнти",
    "suppliers_subtitle": "Дякуємо, що обрали VetMed Pharma своїм брокером! Будь ласка, заповніть форму нижче, і ми зв'яжемося з вами.",
    "contacts_title": "Зворотній ",
    "contacts_title-bold": "зв'язок",
    "contacts_subtitle": "Дякую за вашу зацікавленість до VetMed Pharma. Якщо ви хочете взнати більше про наші сервіси, будь ласка, сконтактуйте з нами онлайн.",
    "contact_us": "Контакти: ",
    "form_title": "Відправка успішна",
    "form_paragraph": "Дякую за витрачений час, щоб зв'язатись з нами.",
}
