export const TRANSLATIONS_MD = {
    "home_link": "Acasă",
    "about_link": "Despre",
    "approach_link": "Abordarea Noastră",
    "clients_link": "Clienții Noștri",
    "services_link": "Servicii",
    "contact_link": "Contacte",
    "company_name": "VetMed Pharma",
    "get_started": "Incepe",
    "address": "Abordare",
    "email": "E-mail",
    "phone": "Telefon",
    "name": "Nume",
    "message": "Mesaj",
    "required": "Necesar",
    "invalid_email": "E-mail invalid",
    "min_char": "Minim 2 caractere.",
    "send_message": "Trimite Mesaj",
    "copyrights": "© VETMED PHARMA | TOATE DREPTURILE REZERVATE.",
    "home_title": "NOI SUNTEM",
    "home_title-bold": "VetMed Pharma",
    "home_subtitle": "Expertiza noastră se referă la dezvoltarea, fabricarea, comercializarea și vânzarea de produse de înaltă calitate exclusiv pentru medicii veterinari de pe piețele interne și ale Comunității Statelor Independente (CSI).",
    "about_title": "Despre",
    "about_paragraph-first": "Scopul nostru este îmbunătățirea durabilă a sănătății și bunăstării animalelor la nivel mondial. VetMed Pharma este o companie specializată în produse farmaceutice veterinare și produse conexe.  Expertiza noastră se referă la dezvoltarea, fabricarea, comercializarea și vânzarea de produse de înaltă calitate exclusiv pentru medicii veterinari de pe piețele interne și ale Comunității Statelor Independente (CSI).",
    "about_paragraph-second": "Tot ceea ce facem este susținut de Cultura și Valorile noastre.  Credem că Valorile noastre încapsulează etica în afaceri și stabilesc standardele pe care dorim să le atingem și, în cele din urmă, să le depășim.  Ele descriu tipul de oameni care suntem, serviciile pe care le oferim și modul în care ne propunem să facem afaceri.  Oferim produse și servicii de înaltă calitate medicilor veterinari din Moldova și din multe alte țări CSI prin angajații noștri și o rețea de terți pentru a îmbunătăți în mod durabil sănătatea și bunăstarea animalelor.",
    "about_quality_standarts_title": "Asigurarea Calitaii",
    "about_quality_standarts_subtitle": "În centrul abordării noastre sunt concepute ",
    "about_quality_standarts_link": "standarde stricte de calitate.",
    "about_relationships_title": "Relații pe termen lunп",
    "about_relationships_subtitle": "Am dezvoltat relații pe termen lung cu furnizorii și clienții pentru a ne dezvolta afacerea de mare succes.",
    "about_clients_needs_title": "Nevoile clienților",
    "about_clients_needs_subtitle": "Cunoașterea așteptărilor și nevoilor clienților tăi este cheia performanței afacerii tale.",
    "approach": "Abordarea noastră",
    "approach_subtitle": "Suntem o echipă dedicată de profesioniști cu adevărat pasionați, care înțeleg nevoile și dorințele clienților noștri.",
    "approach-services": "Servicii",
    "approach-services_title": "Suntem o agenție de creație.",
    "approach-services_subtitle-bold": "Una dintre prioritățile noastre principale este să oferim clienților noștri un nivel de servicii de cinci stele ",
    "approach-services_subtitle": "clienții noștri cu un nivel de servicii de cinci stele.",
    "passionate": "Pasionat",
    "professionals": "Profesionişti",
    "trust": "Încredere",
    "services_title": "Asigurarea ",
    "services_title-bold": "calității",
    "services_subtitle": "Noi, cei de la VetMed Pharma, acordăm prioritate produselor și serviciilor de cea mai înaltă calitate, în timp ce ne desfășurăm activitățile având grijă de sănătatea mediului, a animalelor și a omului. Adoptăm importanța științei, tehnologiei și cercetării și dezvoltării și operăm conform celor mai înalte standarde de calitate ale industriei farmaceutice, care sunt GMP și GLP. Cu sistemul nostru de management al calității împreună cu sistemul nostru de management al lanțului de aprovizionare, ne supraveghem furnizorii pentru a ne asigura că îndeplinesc așteptările noastre. În plus, în produsele noastre folosim ingrediente de cea mai înaltă calitate numai de origine UE. ",
    "services_specialty": "Companii farmaceutice de specialitate",
    "services_formulation": "Companii de cercetare și dezvoltare și formulare",
    "services_chemical": "Companii de catalog chimice",
    "suppliers_title": "Noi furnizori/client",
    "suppliers_subtitle": "Vă mulțumim că ați ales VetMed Pharma ca broker! Vă rugăm să completați formularul de mai jos și vă vom contacta.",
    "contacts_title": "Intrați",
    "contacts_title-bold": "în legătură",
    "contacts_subtitle": "Vă mulțumim pentru interesul dumneavoastră față de VetMed Pharma.  Dacă doriți să aflați mai multe despre soluțiile și serviciile noastre, vă rugăm să nu ezitați să ne contactați online.",
    "contact_us": "Contactaţi-ne",
    "form_title": "Trimitere reușită",
    "form_paragraph": "Vă mulțumim pentru timpul acordat pentru a ne contacta.",
}
